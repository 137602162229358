import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextButton, TextButtonProps } from '~/core/ui/TextButton'
import { cn } from '~/core/ui/utils'

const LongContentDisplay = ({
  isHTML = false,
  content,
  className,
  children,
  textButtonProps = {},
  limitLines = 3
}: {
  isHTML?: boolean
  content: string
  className?: string
  children?: (args: {
    toggleExpanded: (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void
  }) => ReactElement
  textButtonProps?: TextButtonProps
  limitLines?: number
}) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [lineHeight, setLineHeight] = useState<number>(0)
  const [verticalSpace, setVerticalSpace] = useState<number>(0)
  const contentRef = useRef<HTMLParagraphElement>(null)

  const toggleExpanded = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  useEffect(() => {
    const contentElement = contentRef.current
    if (contentElement) {
      const contentHeight = contentElement.clientHeight
      const contentStyle = window.getComputedStyle(contentElement)
      const lineHeight = parseInt(contentStyle.lineHeight)
      setLineHeight(lineHeight)
      setVerticalSpace(parseInt(contentStyle.marginTop))
      setIsOverflowing(contentHeight > lineHeight * limitLines)
    }
  }, [content, contentRef, limitLines])

  return (
    <div>
      <div
        style={{
          maxHeight: expanded
            ? 'unset'
            : lineHeight * limitLines + verticalSpace
        }}
        className={`overflow-hidden whitespace-pre-line`}>
        {isHTML ? (
          <p
            ref={contentRef}
            className={cn('prose dark:prose-invert prose-p:!my-0', className)}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : (
          <p ref={contentRef} className={className}>
            {content}
          </p>
        )}
      </div>
      {isOverflowing && !expanded && (
        <div className="mt-1">
          {children ? (
            children({ toggleExpanded })
          ) : (
            <TextButton
              underline={false}
              size="sm"
              label={`${t('button:viewMore')}`}
              onClick={toggleExpanded}
              {...textButtonProps}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default LongContentDisplay
